<template>
  <div>
    <b-row align-h="between">
      <b-col
        xl="4"
        lg="4"
        md="4"
      >
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-row>
              <b-col>
                <b-skeleton
                  class="ml-2 mt-1"
                  height="25px"
                  type="button"
                />
              </b-col>
            </b-row>
          </template>
          <!-- <span class="text-primary ml-1 p-0">{{ device.network_id }}</span> -->
          <span class="ml-1">
            <b-button
              v-if="!device.is_configure "
              variant="flat-primary"
              size="sm"
              @click="gotoConfigure()"
            >
              Configure
            </b-button>
            <!-- eslint-disable -->
              <b-button
                v-else-if="device.is_configure && device.is_vistar_update"
                variant="flat-primary"
                size="sm"
                @click="gotoConfigure()"
              >
                Reconfigure
              </b-button>
              <b-button
                v-else
                variant="flat-primary"
                size="sm"
                @click="gotoConfigure()"
              >
                Update
              </b-button>
            </span>
            <span class="mt-1">
             <b-badge
              v-if="device.is_configure && device.is_vistar_update"
              class="badge"
              variant="light-danger"
            >
              Reconfiguration Required
            </b-badge>
          </span>
        </b-skeleton-wrapper>
      </b-col>
      <b-col
        align-self="baseline"
        xl="2"
        lg="2"
        md="2"
        class="pl-0"
      >
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-skeleton
              class="ml-2 mt-1"
              height="30px"
              width="50px"
            />
          </template>
          <h5
            style="font-weight: bold;"
            class="text-primary pr-2"
          >
            CPM: {{ device.is_configure ?'$'+ device.cpm_floor_cents : "-" }}
          </h5>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
    <!--Vistar Data skeleton-->
    <!-- <b-skeleton-wrapper :loading="loadingState">
      <template #loading>
        <b-row
          align-h="between"
          class="pl-1"
        >
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
          <div class="w-100" />
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
        </b-row>
      </template>

      <div class="m-0 p-0">
        <b-row
          align-h="between"
          class="pl-1"
        >
          <b-col
            xl="4"
            lg="4"
            md="4"
          > -->
            <!--Venue Type-->
            <!-- <span class="device-info-heading">Venu Type  </span>
            <span class="device-info-text">{{ device.venue_type!==undefined? '- ' + device.venue_type: "-" }}</span>
          </b-col> -->
          <!--Operating Hours-->
          <!-- <b-col
            xl="4"
            lg="4"
            md="4"
          >
            <span
              class="device-info-heading"
            >Operating Hours:
            </span>
            <span class="device-info-text ml-1">
              00:00 - 18:00
            </span>
          </b-col> -->
          <!-- <b-col
            xl="4"
            lg="4"
            md="4"
          >
            <span class="device-info-heading">Impressions Per Spot :  </span>
            <span
              class="device-info-text"
            >{{ device.is_configure ? device.impressions+'/Day' : "-" }}</span>
          </b-col>
          <div class="w-100" /> -->
          <!-- AD Category-->
          <!-- <b-col
            xl="4"
            lg="4"
            md="4"
          >
            <span
              class="device-info-heading"
            >Ad Category:
            </span>
          </b-col> -->
          <!--AD Advisory-->
          <!-- <b-col
            xl="4"
            lg="4"
            md="4"
          >
            <span
              class="device-info-heading"
            >Ad Advisory:
            </span>
          </b-col> -->
        <!-- </b-row>
      </div>
    </b-skeleton-wrapper> -->
    <!-- <b-row
      align-h="start"
      class="ml-1"
    >
      <b-col
        cols="12"
        class="pl-0"
      >
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-skeleton width="25%" />
          </template>
          <span v-if="device.is_configure" class="date text-success ">Activation Date: {{ activationDate }}</span>
        </b-skeleton-wrapper>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BSkeletonWrapper,
  BSkeleton,
  BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSkeleton,
    BSkeletonWrapper,
    BBadge,
  },
  props: {
    deviceData: {
      default: () => ({
        impressions: Number,
        cpm_floor_cents: Number,
        venue_type: String,
      }),
      type: Object,
    },
    loadingState: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    activationDate() {
      const time = moment(this.device.activation_date).format('DD-MM-YYYY HH:mm')
      return time
    },
    device() {
      return this.deviceData
    },

  },
  methods: {
    gotoConfigure() {
      if (!this.device.latitude && !this.device.longitude) {
        this.$swal('Before proceeding to configuration you need to update device location from 0 to someting')
      } else {
        this.$router.push({
          name: 'device-vistar-config',
          params: {
            deviceId: this.$route.params.deviceId,
            userId: this.$route.params.userId,
            deviceName: this.$route.params.deviceName,
          },
        })
      }
    },

  },
}
</script>
<style lang="scss" scoped>
 .device-info-heading{
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
}
//Device Text Typography
 .device-info-text{
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 400;
}

.date{
  color: #28C76F;
  font-weight: 700;

}
</style>
