<template>
  <div>
    <b-row align-h="between">
      <b-col
        xl="4"
        lg="4"
        md="4"
      >
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-row>
              <b-col>
                <b-skeleton
                  class="ml-2 mt-1"
                  height="25px"
                  type="button"
                />
              </b-col>
            </b-row>
          </template>
          <span class="ml-1">
            <b-button
              v-if="!device.is_configure"
              variant="flat-primary"
              size="sm"
              @click="gotoConfigure()"
            >
              Configure
            </b-button>
            <!-- eslint-disable -->
              <b-button
                v-else-if="device.is_hivestack_update && device.is_configure"
                variant="flat-primary"
                size="sm"
                @click="gotoConfigure()"
              >
                Reconfigure
              </b-button>
              <b-button
                v-else
                variant="flat-primary"
                size="sm"
                @click="gotoConfigure()"
              >
                Update
              </b-button>
            </span>
            <span class="mt-1">
             <b-badge
              v-if="device.is_configure && device.is_hivestack_update"
              class="badge"
              variant="light-danger"
            >
              Reconfiguration Required
            </b-badge>
          </span>
          </b-skeleton-wrapper>
      </b-col>
      <b-col
        align-self="baseline"
        xl="2"
        lg="2"
        md="2"
        class="pl-0"
      >
        <!--CPM Skeleton-->
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-skeleton
              class="ml-2 mt-1"
              height="30px"
              width="50px"
            />
          </template>
          <h5
            style="font-weight: bold;"
            class="text-primary pl-1"
          >
            CPM: {{ device.is_configure ? '$'+ device.floor_cpm : "-" }}
          </h5>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
 
    <!--Hive Stack Data Skeleton-->
    <!-- <b-skeleton-wrapper :loading="loadingState">
      <template #loading>
        <b-row
          align-h="between"
          class="pl-1"
        >
          <b-col
            lg="4"
            xl="4"
            md="4"
            class="px-1"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
            class="px-1"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
            class="px-1"
          >
            <b-skeleton width="90%" />
          </b-col>
          <div class="w-100" />
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
          <b-col
            lg="4"
            xl="4"
            md="4"
          >
            <b-skeleton width="90%" />
          </b-col>
        </b-row>
      </template>
      <div class="p-0 m-0">
        <b-row
          align-h="between"
          class="pl-1"
        >
           <b-col
            xl="4"
            lg="4"
            md="4"
          >
            <span
              class="device-info-heading"
            >Operating Hours:
            </span>
            <span
              v-if="device.operating_hours"
              class="device-info-text ml-1"
            >
              {{device.is_configure?  device.operating_hours[0].start: "-" }} - {{device.is_configure? device.operating_hours[0].end:"-" }}
            </span>
          </b-col> -->
          <!-- <b-col
            xl="4"
            lg="4"
            md="4"
            class="p-0"
          >
            <span class="device-info-heading">Impressions Per Spot :  </span>
            <span class="device-info-text">1000/Day</span>
          </b-col> -->
          <!-- <div class="w-100" />
        </b-row>
      </div>
    </b-skeleton-wrapper> -->
    <!-- <b-row
      align-h="start"
      class="ml-1"
    >
      <b-col
        cols="12"
        class="pl-0"
      >
        <b-skeleton-wrapper :loading="loadingState">
          <template #loading>
            <b-skeleton width="25%" />
          </template>
          <span class="date text-success ">Activation Date: 01-05-2023</span>
        </b-skeleton-wrapper>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BSkeletonWrapper,
  BSkeleton,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSkeleton,
    BBadge,
    BSkeletonWrapper,
  },
  props: {
    deviceData: {
      default: () => ({
        operating_hours: Array,
        floor_cpm: Number,
      }),
      type: Object,
    },
    loadingState: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    device() {
      return this.deviceData
    },
  },
  methods: {
    gotoConfigure() {
      if (!this.device.latitude && !this.device.longitude) {
        this.$swal('Before proceeding to configuration you need to update device location from 0 to someting')
      } else {
        this.$router.push({
          name: 'device-hivestack-config',
          params: {
            deviceId: this.$route.params.deviceId,
            userId: this.$route.params.userId,
            deviceName: this.$route.params.deviceName,
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
 .device-info-heading{
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
}
//Device Text Typography
 .device-info-text{
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 400;
}
.date{
  color: #28C76F;
  font-weight: 700;

}
</style>
