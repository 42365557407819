<template>
  <div>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Location"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <validation-observer ref="updateLocationForm">
        <ValidationProvider
          v-slot="{ errors }"
          name="latitude"
          vid="lat"
          rules="required"
        >
          <b-form-group class="m-2">
            <b-form-input
              id="lat"
              v-model.number="deviceData.latitude"
              type="number"
              placeholder="Enter latitude"
              @wheel="onWheel"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="longitude"
          vid="lng"
          rules="required"
        >
          <b-form-group class="m-2">
            <b-form-input
              id="lng"
              v-model.number="deviceData.longitude"
              type="number"
              placeholder="Enter longitude"
              @wheel="onWheel"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </validation-observer>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
  },
  props: {
  },
  emits: ['update-location'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      AccountTypes,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      const data = this.$store.getters['devices/getDevice']
      // Check the type of data
      if (typeof data === 'object') {
        return data
      }
      // Handle invalid data type
      console.warn('Unexpected data type for deviceData:', typeof data)
      return {} // or return a default value
    },

  },
  methods: {
    showLocationModal() {
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    async submitData() {
      try {
        const success = await this.$refs.updateLocationForm.validate()
        if (success) {
          this.dataLoad = true
          const payload = { lat: this.deviceData.latitude, lng: this.deviceData.longitude, macAddress: this.deviceData.mac_address }
          if (this.userRole === this.AccountTypes.ADMIN) {
            const response = await this.$store.dispatch('devices/updateDeviceLocationAdmin', { payload })
            if (response.data.statusCode === 200) {
              showToast('Location Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          } else {
            const response = await this.$store.dispatch('devices/updateDeviceLocationUser', { payload })
            if (response.data.statusCode === 200) {
              showToast('Location Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          }
        } else {
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
      } finally {
        this.dataLoad = false
        this.showLocationModal()
      }
    },

  },
}
</script>
