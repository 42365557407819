<template>
  <div class="device-analytics">
    <b-card
      no-body
      class="overflow-hidden"
    >
      <b-container fluid>
        <b-skeleton-wrapper
          :loading="getLoadedDevice"
        >
          <template #loading>
            <!--Image Section-->
            <b-row class="mt-2">
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton
                  type="button"
                  width="150px"
                />
              </b-col>
              <b-col
                lg="1"
                md="1"
                xl="1"
                class="px-0 mx-0 pl-1"
              >
                <b-skeleton
                  height="35px"
                  width="65px"
                  class="pl-2 mx-0"
                />
              </b-col>
              <!-- <b-col
                lg="1"
                xl="1"
                md="1"
                class="px-0 mx-0"
              >
                <b-skeleton
                  class="px-0 mx-0"
                  type="avatar"
                />
              </b-col> -->

            </b-row>
            <b-row>
              <b-col
                lg="6"
                md="6"
                xl="6"
              >
                <b-skeleton-img
                  aspect="1:1"
                  card-image="left"
                />
              </b-col>
              <b-col
                lg="6"
                xl="6"
                md="6"
              >

                <!--First Section-->
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <!--Third Section-->
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
                <b-skeleton
                  animation="wave"
                  width="85%"
                />

                <!--Last Section-->
                <b-skeleton
                  animation="wave"
                  width="45%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <div class="px-1">
                  <hr class="mb-2">
                </div>
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
                <b-skeleton
                  animation="wave"
                  width="30%"
                />
              </b-col>
            </b-row>

          </template>
          <b-row
            class="pt-2"
          >
            <b-col
              lg="6"
              md="6"
              xl="6"
            >
              <!--DropDown Skeleton-->
              <DeviceDropdown
                :user-status="currentUser.user_has_role[0].role.role === 'admin' ? 'admin': 'user'"
              />
            </b-col>
            <!-- Device Name and Edit Button -->
            <b-col
              xl="3"
              lg="3"
              class="mt-0 p-0"
              md="3"
            >
              <b-row
                class="m-0 p-0"
              >
                <b-col
                  xl="auto"
                  md="auto"
                  lg="auto"
                  col
                  class="p-0 m-0"
                >
                  <b-card-title class="m-0 p-0 device--title">
                    <span class="text-primary ml-1">#{{ device.id }}</span>
                  </b-card-title>
                </b-col>
                <!-- <b-col
                  xl="auto"
                  md="auto"
                  lg="auto"
                  col
                  class="p-0 m-0"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="btn-icon rounded-circle m-0"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                </b-col> -->
              </b-row>
            </b-col>
            <b-col
              lg="3"
              xl="3"
              md="3"
              class="text-end"
            >
              <div class="checkbox">
                <span
                  class="p-0 device-info-heading-1 m-0"
                >
                  Monetization
                </span>
                <b-form-checkbox
                  :checked="device.ssp_opt_in"
                  class="custom-control-primary pt-auto"
                  size="sm"
                  inline
                  switch
                  @change="updateSspOptIn"
                />
                <!-- @change="updateSSpOptIn" -->
              </div>
            </b-col>
          </b-row>
          <!-- align-h="start" -->
          <!-- Image section-->
          <b-row
            class="pb-2 pt-0"
          >
            <b-col
              class="pt-2"
              xl="6"
              lg="6"
              md="6"
            >
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="3000"
                controls
                indicators
                style="background-color: #F8F8F8; position: absolute; width: 95%;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  class="chart-dropdown"
                  toggle-class="p-0"
                  right
                  style="z-index: 5; float: right;"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item @click="openDeleteModal">
                    Delete
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="device.images.length!==5"
                    @click="uploadStatus = !uploadStatus"
                  >
                    Upload Image
                  </b-dropdown-item>
                </b-dropdown>
                <b-carousel-slide
                  v-for="(image,index) in device.images"
                  :key="index"
                >
                  <!-- :img-src="image" -->
                  <template #img>
                    <div
                      class="test-1"
                    >
                      <b-img
                        :src="image"
                        alt="image"
                        fluid
                        rounded
                      />
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <AddImage
                v-if="device.images.length === 0 || uploadStatus"
                :spinner="spinner"
                style="z-index: 3; position: relative; background-color: rgba(0, 0, 0, 0.5); "
                :images-length="device.images.length"
                @upload-image="imageTransfer"
                @close-overlay="uploadStatus = !uploadStatus"
              />
            </b-col>
            <!--Device Analytics-->
            <b-col
              class="pt-2 text-container"
              xl="6"
              lg="6"
              md="6"
            >
              <b-card-body class="p-0">
                <b-card-text>
                  <!--Location Name-->
                  <span
                    v-if="device.user_has_group.name"
                    class="mb-1 device-info-heading"
                  >
                    Group:  <span
                      v-if="device.user_has_group.name"
                      class="device-info-text"
                    >{{ device.user_has_group.name }}</span>
                  </span>
                  <!--Device Contact Information-->
                  <div class="device-info">
                    <!--Email-->
                    <span
                      class="device-info-heading"
                    >Email Address: </span>
                    <span class="device-info-text">
                      {{ device.contact_email }}
                    </span>
                    <br>
                    <!-- Device Phonenumber -->
                    <span
                      class="device-info-heading"
                    >
                      Phone Number:
                    </span>
                    <span class="device-info-text">
                      {{ device.number }}
                    </span>
                    <br>
                    <!--Device Address-->
                    <span class="device-info-heading">
                      Device Address:
                    </span>
                    <span class="device-info-text">
                      {{ device.device_address }}
                    </span>
                  </div>
                  <!--Border Line Div-->
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <!--Venue Section-->
                  <div>
                    <!--Venue Section Type-->
                    <span class="device-info-heading">Venue Name: </span>
                    <!-- <span class="device-info-text">{{ device.venue.venue_name }}</span> -->
                    <span
                      v-if="device.venue.venue_name"
                      class="device-info-text"
                    >{{ device.venue.venue_name }}</span>
                    <br>
                    <span class="device-info-heading">Venu Type - </span>
                    <!-- <span class="device-info-text">{{ device.venue.venue_type }}</span> -->
                    <span
                      class="device-info-text"
                    >{{ device.venue.type.venue_type_name }}</span>
                    <br>
                    <span class="device-info-heading">Venue Address: </span>
                    <!-- <span class="device-info-text">{{ device.venue.venue }}</span> -->
                    <span class="device-info-text">{{ device.venue.address }}</span>
                    <!--Border Line Div-->
                    <div class="px-1">
                      <hr class="mb-2">
                    </div>
                    <!--Operating Hours-->
                    <span
                      class="device-info-heading"
                    >Operating Hours </span>
                    <br>
                    <span class="device-info-heading-1">
                      Start Time: </span>
                    <span class="device-info-text">{{ device.op_start_time }}</span>
                    <br>
                    <span class="device-info-heading-1">
                      End Time: </span>
                    <span class="device-info-text">{{ device.op_end_time }}</span>
                    <br>
                    <!--Venue Impression details-->
                    <!-- <span class="device-info-heading">Impresions Per Spot :  </span>
                    <span class="device-info-text">{{ device.impressions }}/Day</span> -->

                    <span class="device-info-heading">Height: </span>
                    <span class="device-info-text">{{ device.height }}px</span>
                    <span class="ml-4 device-info-heading">Width: </span>
                    <span class="device-info-text">{{ device.width }}px</span>
                    <br>
                    <span class="venue-heading-2">Supports: </span>
                    <b-badge
                      v-if="device.video_support"
                      class="badge ml-2"
                      variant="light-success"
                    >
                      Video
                    </b-badge>
                    <b-badge
                      v-if="device.image_support"
                      class="badge ml-1"
                      variant="light-warning"
                    >
                      Images
                    </b-badge>
                  </div>
                  <div class="px-1">
                    <hr class="mb-2">
                  </div>
                  <div>
                    <span class="device-info-heading">Active Status: </span>
                    <span>
                      <b-badge
                        class="badge"
                        :variant="device.status===DeviceStatus.ACTIVE ? 'light-success' : 'light-danger'"
                      >{{ device.status===DeviceStatus.ACTIVE ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE }}
                      </b-badge>
                    </span>
                  </div>
                  <div>
                    <span class="device-info-heading-1">Latitude: </span>
                    <span class="device-heading-text">{{ device.latitude }}</span>
                    <span class="device-info-heading-1 ml-2">Longitude: </span>
                    <span class="device-heading-text">{{ device.longitude }}</span>
                    <span>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="btn-icon rounded-circle m-0"
                        @click="openUpdateLocationModal"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                  </div>
                </b-card-text>
              </b-card-body>
              <b-row align-h="between">
                <b-col
                  cols="9"
                >
                  <span class="device-info-heading-1">Last Updated: </span>
                  <span class="device-info-text">{{ lastUpdatedDate }}</span>
                </b-col>
                <b-col
                  align-self="end"
                  cols="3"
                  order="12"
                >
                  <div>
                    <h5
                      style="font-weight: bold;"
                      class="text-primary"
                    >
                      CPM: ${{ parseInt(device.cpm) }}
                    </h5>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </b-container>
    </b-card>
    <DeleteImage
      ref="delete-image-modal"
      :images="device.images"
      @delete-image="deleteImage"
    />
    <UpdateDeviceLocation
      ref="update-location-modal"
    />
  </div>
</template>
<script>
import {
  BCard, BBadge, BDropdown, BDropdownItem, BCardImg, BCardTitle, BCardHeader, BCardBody, BMedia,
  BMediaAside, BMediaBody, BLink, BImg, BButton, BAvatar, BSkeletonTable, BProgress, BProgressBar,
  BContainer, BRow, BCol, BOverlay,
  BSkeleton, BSkeletonWrapper, BSkeletonImg, BFormCheckbox, BCardText, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import DeleteImage from '../mediastorage/DeleteImage.vue'
import DeviceDropdown from './DeviceDropdown.vue'
import AddImage from '../mediastorage/AddImage.vue'
import { showToast } from '@/common/global/functions'
import UpdateDeviceLocation from './UpdateDeviceLocation.vue'
import DeviceStatus from '@/common/enums/deviceStatusEnum'

Vue.directive('ripple', Ripple)
export default {
  /* eslint-disable */
  components: {
    DeleteImage,
    BOverlay,
    AddImage,
    BDropdown,
    BDropdownItem,
    BCard,
    BBadge,
    BContainer,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
    BCardTitle,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BSkeleton,
    BSkeletonWrapper,
    BButton,
    BAvatar,
    BSkeletonTable,
    DeviceDropdown,
    BSkeletonImg,
    BFormCheckbox,
    BCarousel,
    BCarouselSlide,
    UpdateDeviceLocation
},
  directives: {
      Ripple,
    },
  /* eslint-enable */
  props: {
    device: {
      type: Object,
      required: true,
    },
    getLoadedDevice: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['load-device', 'load-data'],
  data() {
    return {
      DeviceStatus,
      activeStatus: false,
      slide: 0,
      sliding: null,
      contentHeight: 0,
      spinner: false,
      uploadStatus: false,
      showDeleteModal: false,
      imageIndex: 0,
      imagesFile: [],
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    lastUpdatedDate() {
      return moment(this.device.last_updated_date).utc().format('ddd, MMM Do YYYY, HH:mm:ss')
    },
    sspOptIn() {
      return this.device.ssp_opt_in
    },
  },
  mounted() {
  // Calculate the height of the content and set it to contentHeight
    this.$nextTick(() => {
      const container = this.$el.querySelector('.text-container') // Adjust class name
      if (container) {
        this.contentHeight = container.clientHeight - 20
      }
    })
  },
/* eslint-disable */
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    /* eslint-enable */
    updateSspOptIn(event) {
      this.$emit('load-device', event)
    },
    async deleteImage(value) {
      try {
        const success = await this.$store.dispatch('devices/deleteImage', {
          url: value.item,
          userId: this.device.user_has_group.id,
          deviceMac: this.device.mac_address,
        })
        if (success) {
          showToast('Successful', 'Image deleted successfully', 'success')
          this.openDeleteModal()
          this.$emit('load-data')
        }
      } catch (error) {
        // Catch the error thrown by the Vuex action
        const errorMessage = error.message || 'Unknown error'
        showToast('Failed', errorMessage, 'danger')
      }
    },
    openUpdateLocationModal() {
      this.$refs['update-location-modal'].showLocationModal()
    },
    openDeleteModal() {
      this.$refs['delete-image-modal'].show()
    },
    imageTransfer(images) {
      this.imageIndex = 0
      this.imagesFile = images
      this.imageFile()
      this.spinner = true
    },
    imageFile() {
      if (this.imageIndex >= this.imagesFile.length) {
        showToast('Images uploaded', 'Images have been uploaded', 'success')
        this.spinner = false
        this.uploadStatus = false
        this.$emit('load-data')
      } else {
        this.uploadImage(this.imagesFile[this.imageIndex])
        this.imageIndex += 1
      }
    },
    async uploadImage(fileData) {
      try {
        const results = await this.$store.dispatch('devices/uploadImage', {
          payload: fileData,
          userId: this.device.user_has_group.id,
          deviceMac: this.device.mac_address,
        })
        if (results) {
          showToast('Image Uploaded', 'Image uploaded successfully', 'success')
          this.imageFile()
        } else {
          // Handle the case where at least one upload failed
          showToast('Partial Upload', 'Some images failed to upload', 'warning')
          this.imageFile()
        }
      } catch (error) {
        // Catch the error thrown by the Vuex action
        const errorMessage = error.message || 'Unknown error'
        showToast('Failed', errorMessage, 'danger')
        this.imageFile()
      }
    },

  },
}
</script>
<style scoped lang="scss">
.device-analytics{
  .image-div{
    object-fit: cover;
    width: 100%;
    // height: 100% !important;
  }
  .test-1 {
    width: 100%;
    height: calc(100vmin - 100px);
    object-fit: fill;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }
 .device-info-heading {
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
}
//Second Heading for device
.device-info-heading-1{
  font-size: 14px;
  line-height: 0px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
}
.checkbox{
  float: right;
  text-align: right !important;
}
//Device Text Typography
 .device-info-text{
  // padding-left: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 400;
}
.group-name{
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
  font-family: "Montserrat";
  font-weight: 600;
  }
.badge{
  border-radius: 25px;
  padding-inline: .625rem;
}
.edit-icon{
  padding-top: 0px !important;
  margin-top: 0px !important;
}
}
.custom-control-primary{
  padding-top: 8px;
}
.custom-control-primary ::v-deep
  label.custom-control-label {
    padding: 0px !important;
  }
.device--title{
  padding-top: 4px !important;
}

</style>
